import React, {useState} from 'react'
import {AccordionSummary, Box, AccordionDetails, Typography, Button, Link as RLink} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import {Trans, useTranslation} from "next-i18next";
import {defaultComponent} from "../../utils/i8next/component";
const AccordionFaq = ()=>{
    const {t} = useTranslation(['index-faq']);
    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    return(
        <div style={{ maxWidth: 1030, margin: 'auto' }}>
            <Box mb={2} mt={2}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                    >
                        <Typography style={{ fontSize: '1.3rem' }}>
                            <b>
                                {t('_KEY_FAQ_QUESTION_1', {ns: 'index-faq'})}
                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Box m={2}>
                                <Typography>
                                    <Trans
                                        i18nKey="index-faq:_KEY_FAQ_QUESTION_1_PART_1"
                                        components={{ b: <b/>, br:<br/>}}
                                    />
                                </Typography>
                            </Box>
                        </Box>

                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box mb={2} mt={2}>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                    >
                        <Typography style={{ fontSize: '1.3rem' }}>
                            <b>
                                {t('_KEY_FAQ_QUESTION_2', {ns: 'index-faq'})}
                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Box m={2}>
                                <Typography>
                                    <Trans
                                        i18nKey="index-faq:_KEY_FAQ_QUESTION_2_PART_1"
                                        components={{ b: <b/>, br:<br/>}}
                                    />
                                </Typography>
                            </Box>
                        </Box>

                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box mb={2} mt={2}>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel3bh-content'
                        id='panel3bh-header'

                    >
                        <Typography style={{ fontSize: '1.3rem' }}>
                            <b>
                                {t('_KEY_FAQ_QUESTION_3', {ns: 'index-faq'})}
                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Box m={2}>
                                <Typography>
                                    <Trans
                                        i18nKey="index-faq:_KEY_FAQ_QUESTION_3_PART_1"
                                        components={{ a: <RLink  />,b: <b/>}}
                                    />
                                </Typography>
                            </Box>
                        </Box>

                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box mb={2} mt={2}>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel4bh-content'
                        id='panel4bh-header'
                    >
                        <Typography style={{ fontSize: '1.3rem' }}>
                            <b>
                                {t('_KEY_FAQ_QUESTION_4', {ns: 'index-faq'})}

                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Box m={2}>
                                <Typography>
                                    {t('_KEY_FAQ_QUESTION_4_PART_1', {ns: 'index-faq'})}
                                </Typography>
                            </Box>
                        </Box>

                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box mb={2} mt={2}>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel5bh-content'
                        id='panel5bh-header'

                    >
                        <Typography style={{ fontSize: '1.3rem' }}>
                            <b>
                                {t('_KEY_FAQ_QUESTION_5', {ns: 'index-faq'})}

                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Box m={2}>
                                <Typography>
                                    {t('_KEY_FAQ_QUESTION_5_PART_1', {ns: 'index-faq'})}
                                </Typography>
                            </Box>
                        </Box>

                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box mb={2} mt={2}>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel6bh-content'
                        id='panel6bh-header'

                    >
                        <Typography style={{ fontSize: '1.3rem' }}>
                            <b>
                                {t('_KEY_FAQ_QUESTION_6', {ns: 'index-faq'})}
                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Box m={2}>
                                <Typography component='div'>
                                    <Trans
                                        i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_1"
                                        components={{ b: <b/>}}
                                    />
                                    <ul>
                                        <li>
                                            <Trans
                                                i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_2"
                                                components={{ l:<RLink  target='_blank' rel='noreferrer' />, b: <b/>}}
                                            />
                                        </li>
                                        <li>
                                            <Trans
                                                i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_2_1"
                                                components={{
                                                    l:<RLink  target='_blank' rel='noreferrer'  />,
                                                    l2:<RLink  target='_blank' rel='noreferrer'  />,
                                                    b: <b/>,
                                            }}
                                            />


                                        </li>
                                        <li>
                                            <Trans
                                                i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_3"
                                                components={{ l:<RLink target='_blank' rel='noreferrer' />, b: <b/>}}
                                            />

                                        </li>
                                    </ul>
                                    <Trans
                                        i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_4"
                                        components={{ b: <b/>}}
                                    />
                                    <ul>
                                        <li>
                                            <Trans
                                                i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_5"
                                                components={{ b: <b/>}}
                                            />

                                        </li>
                                        <li>
                                            <Trans
                                                i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_6"
                                                components={{ b: <b/>}}
                                            />

                                        </li>
                                        <li>
                                            <Trans
                                                i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_7"
                                                components={{ l:<RLink  target='_blank' rel='noreferrer'  />, b: <b/>}}
                                            />
                                        </li>
                                        <li>
                                            <Trans
                                                i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_8_1"
                                                components={{ l:<RLink  target='_blank' rel='noreferrer'  />, b: <b/>}}
                                            />
                                        </li>
                                    </ul>
                                    <Trans
                                        i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_8"
                                        components={{ l:<RLink  target='_blank' rel='noreferrer' />, b: <b/>,br:<br/>}}
                                    />

                                    <Trans
                                        i18nKey="index-faq:_KEY_FAQ_QUESTION_6_PART_9"
                                        components={{ l:<RLink  target='_blank' rel='noreferrer' />, b: <b/>,br:<br/>}}
                                    />

                                </Typography>
                            </Box>
                        </Box>

                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box mb={2} mt={2}>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel7bh-content'
                        id='panel7bh-header'

                    >
                        <Typography style={{ fontSize: '1.3rem' }}>
                            <b>
                                {t('_KEY_FAQ_QUESTION_7', {ns: 'index-faq'})}
                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Box m={2}>
                                <Typography>
                                    {t('_KEY_FAQ_QUESTION_7_PART_1', {ns: 'index-faq'})}
                                </Typography>
                            </Box>
                        </Box>

                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box mb={2} mt={2}>
                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel8bh-content'
                        id='panel8bh-header'
                    >
                        <Typography style={{ fontSize: '1.3rem' }}>
                            <b>
                                {t('_KEY_FAQ_QUESTION_8', {ns: 'index-faq'})}
                            </b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Box m={2}>
                                <Typography>
                                    {t('_KEY_FAQ_QUESTION_8_PART_1', {ns: 'index-faq'})}
                                    <br />
                                    {t('_KEY_FAQ_QUESTION_8_PART_2', {ns: 'index-faq'})}
                                    <br />
                                    {t('_KEY_FAQ_QUESTION_8_PART_3', {ns: 'index-faq'})}
                                   <br />
                                    <Trans
                                        i18nKey="index-faq:_KEY_FAQ_QUESTION_8_PART_4"
                                        components={{ l:<RLink  target='_blank' rel='noreferrer' />, b: <b/>,br:<br/>}}
                                    />

                                </Typography>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box mt={5} >
                <Trans
                    i18nKey="index-faq:_KEY_FAQ_ANNEXE"
                    components={defaultComponent()}
                />
            </Box>
        </div>
    )
}
export default  AccordionFaq