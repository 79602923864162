import  React from 'react'
import Box from "@mui/material/Box";
import TITLE_MAIN_PAGE_CSS from "./title-main-page.styles";
const TitleMainPageComponent = ({ sx=null, children})=>{

    return(
        <Box sx={{...TITLE_MAIN_PAGE_CSS,sx}}>
            {children}
        </Box>
    );

}
export  default TitleMainPageComponent