const TITLE_MAIN_PAGE_CSS={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.1em",
    my:5
}
export  default  TITLE_MAIN_PAGE_CSS